import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { handleLinkClick, handleStrippedLinkClick, stripHashedLocation } from '../utils/scroll'

const AnchorLink = ({ to, title, children, className, stripHash }) => {
  const linkProps = {
    to: stripHash ? stripHashedLocation(to) : to,
    onClick: (e) => (stripHash ? handleStrippedLinkClick(to, e) : handleLinkClick(to, e)),
  }

  if (title) linkProps.title = title
  if (className) linkProps.className = className

  return <Link {...linkProps}>{children || title}</Link>
}

AnchorLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  stripHash: PropTypes.bool,
  className: PropTypes.string,
}

AnchorLink.defaultProps = {
  stripHash: false,
}

export default AnchorLink
