import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import { artDirecting } from '../utils/components'
import { ImageType, StyleType } from '../utils/prop-types'

const Image = ({ style, image, imageMobile, alt, avoidGatsbyImage, ...props }) => {
  const [alternativeTitle, setAlternativeTitle] = useState('')
  const [alternativeAlt, setAlternativeAlt] = useState('')

  useEffect(() => {
    if (image && image.url) {
      setAlternativeTitle(image.url.split('/').pop().replace(/[0-9]/g, '').substring(1))
      setAlternativeAlt(`${alternativeTitle.replace(/\.[^/.]+$/, '')} picture`)
    }
  })

  if (image && image.format && image.format === 'svg') {
    return imageMobile && imageMobile.url ? (
      <picture css={[tw`w-full h-full`, style]}>
        <source media="(max-width: 1023px)" srcSet={imageMobile.url} type="image/svg+xml" />
        <source srcSet={image.url} type="image/svg+xml" />
        <img
          css={[tw`w-full h-full`, style]}
          src={image.url}
          alt={alt || alternativeAlt}
          title={alternativeTitle}
        />
      </picture>
    ) : (
      <img
        css={[tw`w-full h-full`, style]}
        src={image.url}
        alt={alt || alternativeAlt}
        title={alternativeTitle}
        loading="lazy"
      />
    )
  }

  if (avoidGatsbyImage && image.url)
    return (
      <img
        css={[tw`w-full h-full`, style]}
        src={image.url}
        alt={alt || alternativeAlt}
        title={alternativeTitle}
      />
    )

  return (
    <GatsbyImage
      css={style}
      image={artDirecting(image, imageMobile)}
      alt={alt || '...'}
      title="image"
      {...props}
    />
  )
}

Image.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  style: StyleType,
  alt: PropTypes.string,
  props: PropTypes.array,
}

export default Image
