import { theme } from 'twin.macro'

const generateMin = (pixels) => `@media (min-width: ${pixels})`
const generateMax = (pixels) => `@media (max-width: ${pixels})`

// legacy media queries to be refactored to utilities below
export const xs = generateMin(theme`screens.xs`)
export const sm = generateMin(theme`screens.sm`)
export const md = generateMin(theme`screens.md`)
export const lg = generateMin(theme`screens.lg`)
export const xl = generateMin(theme`screens.xl`)
export const xxl = generateMin(theme`screens.xxl`)

export const min = {
  xs: generateMin(theme`screens.xs`),
  sm: generateMin(theme`screens.sm`),
  md: generateMin(theme`screens.md`),
  lg: generateMin(theme`screens.lg`),
  xl: generateMin(theme`screens.xl`),
  xxl: generateMin(theme`screens.xxl`),
}

export const max = {
  xs: generateMax(`${theme`screens.xs`.slice(0, -2) - 1}px`),
  sm: generateMax(`${theme`screens.sm`.slice(0, -2) - 1}px`),
  md: generateMax(`${theme`screens.md`.slice(0, -2) - 1}px`),
  lg: generateMax(`${theme`screens.lg`.slice(0, -2) - 1}px`),
  xl: generateMax(`${theme`screens.xl`.slice(0, -2) - 1}px`),
  xxl: generateMax(`${theme`screens.xxl`.slice(0, -2) - 1}px`),
}
