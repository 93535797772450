import { Link as GatsbyLink } from 'gatsby'
import find from 'lodash/find'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import React from 'react'
import Select, { components } from 'react-select'
import tw, { css, theme } from 'twin.macro'
import { usePageContext } from '../context/page-context'
import first from 'lodash/first'
import { ReactComponent as Indicator } from '../images/icon-caret-down.svg'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <Indicator />
  </components.DropdownIndicator>
)

const Option = ({ children, ...props }) => {
  const { data } = props  
    return (
      <GatsbyLink aria-label={` Change site language to ${data.fullLabel}`} to={data.link}>
        <components.Option {...props}>{children}</components.Option>
      </GatsbyLink>
    )
}

Option.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    fullLabel: PropTypes.string,
    link: PropTypes.string,
  }),
}

const selectorStyle = () => ({
  control: (provided, { isDisabled }) => ({
    ...provided,
    width: '100px',
    padding: '10px',
    border: 'none',
    borderRadius: '0px',
    color: theme`colors.blue-dark`,
    backgroundColor: isDisabled ? theme`colors.smoke-grey` : theme`colors.primary.100`,
    boxShadow: 'none',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px',
      width: '66px',
      //minWidth: '66px', //Replaced by fixed width after add 6 items on the main nav
      //minWidth: '120px', //When label is the fullName, i.e. English
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transition: 'transform 0.3s ease-in-out',
    transform: state.selectProps.menuIsOpen && 'scaleY(-1)',
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '500',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      fontWeight: '500',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, { isDisabled }) => ({
    ...provided,
    display: isDisabled ? 'none' : 'flex',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '5px',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
    transition: 'all 0.3s ease-in-out',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme`colors.blue-dark`,
    width: '100%',
    opacity: '0.7',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    display: state.isSelected ? 'none' : 'block',
    padding: '8px 16px',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px 16px',
    },
    color: theme`colors.blue-dark`,
    backgroundColor: theme`colors.primary.100`,
    '&:hover': {
      backgroundColor: theme`colors.select-hover`,
    },
    border: 'none',
    borderRadius: '0px',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    padding: '8px 16px',
    '@media (min-width: 1024px)': {
      ...provided['@media (min-width: 1024px)'],
      padding: '8px 16px',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 8px',
    color: theme`colors.blue-dark`,
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
    color: theme`colors.primary.500`,
    textOverflow: 'initial',
  }),
})

const menuStyle = css`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  margin-top: 0;
  animation: fadeIn 0.2s ease-in-out;
`

const Menu = (props) => {
  const { children } = props

  return (
    <components.Menu {...props} css={menuStyle}>
      {children}
    </components.Menu>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

const LanguagePicker = ({ style, url, isDisabled, switcher }) => {
  const { originalPath, locales, locale, lang, supportedLocales } = usePageContext()
  const options = map(locales, (l) => ({
    label: l.label,    
    fullLabel: l.fullName,
    value: l.lang,
    link: `/${l.lang}${originalPath}`,
  }))

  const defaultValue = find(options, { value: lang })
  return (
    switcher ?     
      <div css={style}>
      {supportedLocales.sort().reverse().map((l, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <span css={tw`text-xl font-bold`}>|</span>}
          <GatsbyLink
            css={(locale === l && tw`font-bold`, tw`text-xl font-semibold p-2`)}
            aria-label={`Change language to ${locales[l].label}`}
            to={`/${locales[l].lang}${originalPath}`}
          >
            {locales[l].label.toUpperCase()}
          </GatsbyLink>
        </React.Fragment>
      ))}
    </div>
  : <Select
      name="language-selector"
      isSearchable={false}
      components={{ DropdownIndicator, Option, Menu }}
      styles={selectorStyle()}
      isDisabled={isDisabled}
      css={[style]}
      options={options}
      value={defaultValue}
    />
  )
}

LanguagePicker.propTypes = {
  style: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
}

export default LanguagePicker