import PropTypes from 'prop-types'
import React from 'react'
import tw, { css, theme } from 'twin.macro'
import quote from '../images/icon-quote.svg'
import { global } from '../styles/global'
import { min } from '../utils/breakpoints'
import { RequiredHTMLType, StyleType } from '../utils/prop-types'

export const typography = css`
  > *:last-child {
    ${tw`pb-0 mb-0`}
  }
  ul,
  hr {
    ${tw`mt-10 mb-20`}
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`mb-8`}
  }
  a {
    ${tw`font-medium transition duration-300 ease-in-out text-primary-500 text-opacity-77`}
    text-decoration: underline solid ${theme`colors.primary.500`};
    transition-property: text-decoration, color;
    &:hover {
      ${min.lg} {
        text-decoration: underline solid transparent;
        ${tw`text-opacity-100`}
      }
    }
  }
  blockquote {
    &:before {
      content: url(${quote});
      ${tw`block mb-8`}
    }
  }
  table {
    ${tw`mb-10`}
    thead {
      ${tw`bg-primary-50`}
    }
    th,
    td {
      ${tw`px-4 py-2 border border-line-grey`}
    }
  }
  img {
    ${tw`my-8 lg:my-12`}
  }
`

export const inlineTypography = css`
  p {
    ${tw`mb-2 lg:mb-8 last-of-type:mb-0`}
  }
  a {
    ${tw`font-bold text-primary-500`}
    ${min.lg} {
      ${tw`relative inline-block overflow-hidden align-bottom`}
      &:after {
        ${tw`absolute inset-x-0 bottom-0 w-full h-px transition-transform duration-300 ease-in-out transform -translate-x-full opacity-0 bg-primary-500`}
        content: '';
      }
      &:hover {
        &:after {
          ${tw`transform translate-x-0 opacity-100`}
        }
      }
    }
  }
`

export const blockquoteStyle = css`
  blockquote {
    &:before {
      content: url(${quote});
      ${tw`block mb-8`}
    }
  }
`

const Text = ({ content, parentEl, style, textType, ...props }) => {
  const Parent = parentEl
  if (content && !content.childMarkdownRemark) {
    return (
      <Parent css={[textType && global`typography`[textType], inlineTypography, style]} {...props}>
        {content}
      </Parent>
    )
  }

  if (content && content.childMarkdownRemark.html) {
    return (
      <Parent
        css={[textType && global`typography`[textType], inlineTypography, style]}
        dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
        {...props}
      />
    )
  }

  return null
}

Text.propTypes = {
  content: RequiredHTMLType,
  parentEl: PropTypes.string,
  style: StyleType,
  textType: PropTypes.string,
  props: PropTypes.object,
}

Text.defaultProps = {
  parentEl: 'div',
}

export default Text
