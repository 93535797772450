import { Global } from '@emotion/react'
import React from 'react'
import tw, { css } from 'twin.macro'
import { lg } from '../utils/breakpoints'

export const emotion = {
  'hover-underline': (background = tw`bg-primary-500`, height = tw`h-1`) => css`
    ${lg} {
      ${tw`relative overflow-hidden`}
      &:after {
        ${tw`absolute inset-x-0 bottom-0 w-full transition-transform duration-300 ease-in-out transform -translate-x-full opacity-0`}
        ${background}
      ${height}
      content: '';
      }
      &:hover {
        img {
          ${tw`transform -translate-y-1`}
        }
        &:after {
          ${tw`transform translate-x-0 opacity-100`}
        }
      }
    }
  `,
  'bold-to-medium': css`
    strong,
    b {
      ${tw`font-medium`}
    }
  `,
  'bold-to-normal': css`
    strong,
    b {
      ${tw`font-normal`}
    }
  `,
}

export default function Base() {
  return (
    <Global
      styles={css`
        a,
        button {
          ${tw`outline-none`}
          &:focus {
            ${tw`outline-none`}
          }
        }
      `}
    />
  )
}
