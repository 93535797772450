import get from 'lodash/get'
import { withArtDirection } from 'gatsby-plugin-image'

export function legacyArtDirecting(image, mobileImage) {
  return mobileImage
    ? [
        mobileImage.fluid,
        {
          ...image.fluid,
          media: '(min-width: 1024px)',
        },
      ]
    : get(image, 'fluid')
}

export function artDirecting(image, mobileImage) {
  return mobileImage
    ? withArtDirection(image.gatsbyImageData, [
        {
          media: '(max-width: 1023px)',
          image: mobileImage.gatsbyImageData,
        },
      ])
    : get(image, 'gatsbyImageData')
}
