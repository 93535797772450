import styled from '@emotion/styled'
import has from 'lodash/has'
import PropTypes from 'prop-types'
import React from 'react'
import { HTMLType, StyleType } from '../utils/prop-types'

const HeadingComponent = styled.h1``

const Heading = ({ headingType, content, style, onClick }) => {
  if (content && !has(content, 'childMarkdownRemark')) {
    return (
      <HeadingComponent as={headingType} css={[style]} onClick={onClick}>
        {content}
      </HeadingComponent>
    )
  }

  if (content && content.childMarkdownRemark && content.childMarkdownRemark.html) {
    return (
      <HeadingComponent
        as={headingType}
        css={[style]}
        dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
      />
    )
  }

  return null
}

Heading.propTypes = {
  headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  content: PropTypes.oneOfType([HTMLType, PropTypes.node]),
  style: StyleType,
}

export default Heading
