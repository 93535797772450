import React from 'react'
import tw, { css, theme } from 'twin.macro'
import { lg, md, xl, xxl } from '../utils/breakpoints'

export const fluid = (min, max) => {
  const minVw = theme`screens.xs`.slice(0, -2)
  const maxVw = theme`screens.xxl`.slice(0, -2)
  return `calc(${min}px + ${max - min} * ((100vw - ${minVw}px) / ${maxVw - minVw - 1}))`
}

export const emotion = {
  container: css`
    ${tw`w-full px-container-mobile`}

    ${lg} {
      ${tw`w-auto max-w-lg px-0 mx-auto`}
    }

    ${xl} {
      ${tw`max-w-xl`}
    }

    ${xxl} {
      ${tw`max-w-xxl`}
    }
  `,
  'desktop-container': css`
    ${lg} {
      ${tw`w-auto max-w-lg px-0 mx-auto`}
    }

    ${xl} {
      ${tw`max-w-xl`}
    }

    ${xxl} {
      ${tw`max-w-xxl`}
    }
  `,
  'mobile-container': css`
    ${tw`w-full px-container-mobile`}
    ${lg} {
      ${tw`w-auto px-0`}
    }
  `,
  grid: css`
    ${tw`grid grid-cols-sm gap-x-default`}

    ${md} {
      ${tw`grid-cols-md`}
    }

    ${lg} {
      ${tw`grid-cols-lg`}
    }

    ${xl} {
      ${tw`grid-cols-xl`}
    }

    ${xxl} {
      ${tw`grid-cols-xxl`}
    }
  `,
  'desktop-grid': css`
    ${lg} {
      ${tw`grid grid-cols-lg gap-x-default`}
    }

    ${xl} {
      ${tw`grid-cols-xl`}
    }

    ${xxl} {
      ${tw`grid-cols-xxl`}
    }
  `,
  'platform-grid': css`
    ${lg} {
      ${tw`grid grid-cols-lg grid-rows-lg gap-x-default`}
    }

    ${xl} {
      ${tw`grid-cols-xl grid-rows-xl`}
    }

    ${xxl} {
      ${tw`grid-cols-xxl grid-rows-xxl`}
    }
  `,
  'full-width': css`
    ${tw`relative w-screen left-1/2 right-1/2 -mx-1/2-screen`}
  `,
}

export default function Layout() {
  return <></>
}
